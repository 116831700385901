.login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .lContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .lInput{
    height: 30px;
    padding: 10px;
  }
  
  .lButton {
    border: none;
    padding: 10px 20px;
    background-color: #0071c2;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .lButton:disabled{
    background-color: #0071c28c;
    cursor: not-allowed;
  }