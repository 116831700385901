.datatableHome {
  height: 400px;
  padding: 20px;

  .datatableTitleHome {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }

    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
}

.link-Home {
  text-decoration: none;
  color: green;
  font-size: 16px;
  font-weight: 400;
  width: auto;
  border: 1px solid green;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.new .newContainer .bottom .right .form {
  display: flex;
  flex-direction: column !important;
  .title{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 2rem;
    color: #db9e36;
    text-decoration: underline;

  }
  .formInput {
    width: 100% !important;
    flex-wrap: wrap;
    
    .landingImage {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
    }
  }
}