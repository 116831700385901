.new {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }



      .right {
        flex: 2;
        padding: 0px 20px;

        .left {
          flex: 1;
          text-align: center;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
  
          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-between;

          .formInput {
            width: 30% !important;
          }
          .formInput-width-50 {
            width: 45% !important;
          }

          .formInput-width-100 {
            width: 100% !important;
          }

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
          

          button {
            width: 150px;
            padding: 10px;
            border: none;
            background-color: teal;
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
          }

          
        }

        .tabs{
          padding-top: 20px;

          

          // ---------------------------------- Error msg style ---------------------------
          .errorMessage{
            font-family: Arial, Helvetica, sans-serif;
            width: 100%;
            color: teal;
            padding-top: 0;
            font-size: small;
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
.descriptionTable{
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.descriptionTable td, .descriptionTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.descriptionTable tr:nth-child(even){background-color: #f2f2f2;}

.descriptionTable tr:hover {background-color: #ddd;}

.descriptionTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #00aaaa;
  color: white;
}
